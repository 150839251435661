 .ant-btn-primary {
  min-width: 14vw;
}

.ant-modal .ant-btn {
  width: 100%;
}

.ant-modal-confirm-body {
  display: flex;
  justify-content: flex-end;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  float: none;
}

.ant-typography {
  color: white;
}

@primary-purple: #691EBF;@primary-blue: #349DC7;@primary-green: #2AAF9E;@primary-pink: #BE005D;@primary-red: #E74E68;